/* eslint-disable no-useless-computed-key */
import { useState, useEffect, useRef } from 'react';
import Markdown from 'markdown-to-jsx';

import { styled, Card, CardHeader, CardMedia, CardContent, CardActions, Collapse, 
        IconButton, Typography, TextField, 
        Button, ButtonGroup, Stack, Divider, Link, LinearProgress, 
        linearProgressClasses, Box, Alert, AlertTitle, Pagination, 
        Snackbar, Skeleton, Badge } from '@mui/material';
import CircularProgress from '@mui/material/CircularProgress';

import useSound from 'use-sound';

import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import ExpandLessIcon from '@mui/icons-material/ExpandLess';
import RedditIcon from '@mui/icons-material/Reddit';
import ContentCopyRoundedIcon from '@mui/icons-material/ContentCopyRounded';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import FavoriteIcon from '@mui/icons-material/Favorite';
import FlipIcon from '@mui/icons-material/Flip';
import CloseIcon from '@mui/icons-material/Close';
import RefreshIcon from '@mui/icons-material/Refresh';
import InfoTwoToneIcon from '@mui/icons-material/InfoTwoTone';

import "./component-css/boss-details.css";
import { CopyToClipboardButton } from './';
import PlayerCommentSound from './PlayerComment.ogg';
import Moment from 'react-moment';
import moment from 'moment';
import Flippy, { FrontSide, BackSide } from 'react-flippy';
import { isMobile } from 'react-device-detect';

const ExpandMore = styled((props) => {
  const { expand, ...other } = props;
  return <IconButton {...other} />;
})(({ theme, expand }) => ({
  transform: !expand ? 'rotateY(0deg)' : 'rotateY(180deg)',
  marginLeft: 'auto',
  transition: theme.transitions.create('transform', {
    duration: '0.6s',
  }),
}));
  
const BorderLinearProgress = styled(LinearProgress)(({ theme }) => ({
  height: 10,
  borderRadius: 5,
  [`&.${linearProgressClasses.colorPrimary}`]: {
    backgroundColor: theme.palette.grey[200],
  },
  [`& .${linearProgressClasses.bar}`]: {
    borderRadius: 5,
    backgroundColor: '#ff0000',
  },
}));

const elementIcons = {
  Air: 'https://cdn.discordapp.com/emojis/1051333997323628564.webp?size=48&quality=lossless',
  Bles: 'https://cdn.discordapp.com/emojis/1042933454708408350.webp?size=48&quality=lossless',
  Curs: 'https://cdn.discordapp.com/emojis/1042933519703343304.webp?size=48&quality=lossless',
  Erth: 'https://cdn.discordapp.com/emojis/1051333985499885648.webp?size=48&quality=lossless',
  Fire: 'https://cdn.discordapp.com/emojis/1042933464594391151.webp?size=48&quality=lossless',
  Moon: 'https://cdn.discordapp.com/emojis/1042933456579072040.webp?size=48&quality=lossless',
  Org: 'https://cdn.discordapp.com/emojis/1042933517727825980.webp?size=48&quality=lossless',
  Sun: 'https://cdn.discordapp.com/emojis/1042933458357461042.webp?size=48&quality=lossless',
  Syn: 'https://cdn.discordapp.com/emojis/1042933460475592765.webp?size=48&quality=lossless',
  Wtr: 'https://cdn.discordapp.com/emojis/1042933352770064394.webp?size=48&quality=lossless',
};

moment.relativeTimeThreshold('m', 60);

const defaultPlayerDetails = {
  loading: false,
  waitingForBot: false,
  validAttack: false,
  created: '', 
  command: '', 
  elementResult: '', 
  element: '',
  id: '', 
  elementUnknown: false, 
  damage: 0, 
  gold: 0
};

const defaultPlayerNotice = {
  message: '',
  severity: '',
  show: false
};


const BossDetails = ({ boss, deadBoss, deadBosses }) => {

  const [currentHealth, setCurrentHealth] = useState(boss.health.currentHealth);
  const [maxGold, setMaxGold] = useState(boss.maxGold);
  const [maxDmg, setMaxDmg] = useState(boss.maxDmg);
  //const [bossComments, updateBossComments] = useState([]);
  const [allBossComments, setAllBossComments] = useState([]);
  const [incomingPlayerComment] = useSound(PlayerCommentSound);
  const [expanded, setExpanded] = useState(false);
  const [showResult, setShowResult] = useState(false);
  const [loadingComments, setLoadingComments] = useState(false);
  const [storedPlayerName] = useState(localStorage.getItem('storedPlayerName'));
  const [currentCommentPage, setCommentPage] = useState(0);
  const [playerHealthComments, updatePlayerHealthComments] = useState([]);
  const [playerBossDetails, setPlayerBossDetails] = useState(defaultPlayerDetails);
  const [playerBossDetailsBackup, backupPlayerBossDetails] = useState(defaultPlayerDetails);
  const [playerNotice, setPlayerNotice] = useState(defaultPlayerNotice);
  const bossDmgUpdate = useRef();
  const playerHealthUpdate = useRef();
  const updatedPlayerBossDetails = useRef();
  
  if (!bossDmgUpdate.current) {
    bossDmgUpdate.current = new BroadcastChannel('boss-damage');
  }
  
  if (!playerHealthUpdate.current) {
    playerHealthUpdate.current = new BroadcastChannel('player-health');
  }

  if(!updatedPlayerBossDetails.current) {
    if(localStorage.getItem('storedPlayerBossData')) {
      let playerBossData = JSON.parse(localStorage.getItem('storedPlayerBossData'));
      if(playerBossData[boss.id]) {
        updatedPlayerBossDetails.current = {
          ...playerBossData[boss.id],
          loading: false,
          waitingForBot: false
        };
        if(playerBossData[boss.id] && !playerBossDetails.id) {
          setPlayerBossDetails(updatedPlayerBossDetails.current);
        }
      }
    }
  }

  useEffect(() => {
     
    //console.log('boss-details useEffect called')
    const playerCommentsUpdatRef = new BroadcastChannel('player-comments');
    const bossCommentsUpdateRef = new BroadcastChannel('boss-comments');
    const bossPlayerData = new BroadcastChannel('boss-player-data');

    bossPlayerData.addEventListener("message", (event) => {
      const playerBossData = event?.data;
      const bossData = playerBossData[boss.id];
      updatedPlayerBossDetails.current = {
        ...(bossData || playerBossDetails),
        loading: false,
        waitingForBot: false
      };
      setPlayerBossDetails(updatedPlayerBossDetails.current);

      playerBossData[boss.id] = updatedPlayerBossDetails.current;
      localStorage.setItem('storedPlayerBossData', JSON.stringify(playerBossData));
    })

    bossCommentsUpdateRef.addEventListener("message", (event) => {
      const newBossComment = event?.data;
      if(newBossComment && newBossComment.bossID.includes(boss.id)) {
        const parentID = newBossComment.parentID;
        const pageIndex = allBossComments.findIndex(page => page.filter(comment => parentID.includes(comment.id)).length);

        if(allBossComments.length && pageIndex >= 0) {
          const {
            [0]: {
              index: commentIndex
            }
          } = allBossComments[pageIndex].map((comment, index) => ({id: comment.id, index})).filter(comment => parentID.includes(comment.id));
  
          const seletedComment = allBossComments[pageIndex][commentIndex];
          
          setAllBossComments([
            ...allBossComments.slice(0, pageIndex),
            [
              ...allBossComments[pageIndex].slice(0, commentIndex),
              {
                ...seletedComment,
                replies: [
                  ...seletedComment.replies,
                  {
                    ...newBossComment,
                    show: false,
                  },
                ]
              },
              ...allBossComments[pageIndex].slice(commentIndex + 1),
            ],
            ...allBossComments.slice(pageIndex + 1)
          ]);
        }

        const {
          bossHealth,
          tooSoonMatch,
          playerHealth,
          specials
        } = newBossComment;

        Object.keys(specials).forEach((s) => {
          if(specials[s]) {
            boss.specialStats[s].push(true);
          }
        })

        if(bossHealth !== 'No Update' && +currentHealth > +bossHealth) {
          if(+bossHealth <= 5) {
            deadBoss([
              ...deadBosses,
              boss.id
            ]);
            return;
          }
          setCurrentHealth(bossHealth);
          const calcMaxGold = Math.floor((0.086 * boss.health.maxHealth ** 0.547 * (boss.health.maxHealth - bossHealth) ** 0.263 + 10) * boss.stars.length ** 0.167);
          const calcMaxDmg = Math.ceil(0.08 * boss.health.maxHealth ** 0.15 * bossHealth ** 0.5 * boss.stars.length ** 1.7);
          setMaxGold(calcMaxGold);
          setMaxDmg(calcMaxDmg);
          bossDmgUpdate.current.postMessage({
            boss: boss,
            maxDmg: calcMaxDmg
          });
        }

        if(playerBossDetails.id && parentID.includes(playerBossDetails.id) && newBossComment.body.includes('💥') && playerBossDetails.validAttack) {
          const newPlayerBossDetails = {
            ...playerBossDetails,
            ...newBossComment
          };

          if(localStorage.getItem('storedPlayerBossData')) {
            let playerBossData = JSON.parse(localStorage.getItem('storedPlayerBossData'));
            playerBossData[boss.id] = newPlayerBossDetails;
            localStorage.setItem('storedPlayerBossData', JSON.stringify(playerBossData));
          }

          setPlayerBossDetails(newPlayerBossDetails);
          setShowResult(true); 
        } else if(playerBossDetails.id && newBossComment.parentID.includes(playerBossDetails.id) && !newBossComment.body.includes('💥') && playerBossDetails.validAttack) {
          setPlayerBossDetails({
            ...playerBossDetailsBackup,
            loading: false,
            waitingForBot: false
          });
          if(tooSoonMatch) {
            setPlayerNotice({
              message: tooSoonMatch,
              severity: 'warning',
              show: true
            });
          }
          if(localStorage.getItem('storedPlayerBossData')) {
            let playerBossData = JSON.parse(localStorage.getItem('storedPlayerBossData'));
            playerBossData[boss.id] = playerBossDetailsBackup;
            localStorage.setItem('storedPlayerBossData', JSON.stringify(playerBossData));
          }
        }

        if(playerHealthComments.includes(parentID) && playerHealth !== '') {
          playerHealthUpdate.current.postMessage(playerHealth);
        }
      }
    })
    
    
    playerCommentsUpdatRef.addEventListener("message", (event) => {
      const newComment = event?.data;
      if(newComment && newComment.bossID.includes(boss.id)) {
        if(expanded) {
          incomingPlayerComment();
        }

        const {
          validHealthCommand,
          author,
          id,
          validAttack
        } = newComment;
      
        if(storedPlayerName.toLowerCase() === author.toLowerCase()) {
          if(validHealthCommand) {
            updatePlayerHealthComments([
              ...playerHealthComments,
              id
            ]);
          }

          if(validAttack) {
            backupPlayerBossDetails(playerBossDetails);
            setPlayerBossDetails({
              ...playerBossDetails,
             ...newComment
            }); 

            if(localStorage.getItem('storedPlayerBossData')) {
              let playerBossData = JSON.parse(localStorage.getItem('storedPlayerBossData'));
              playerBossData[boss.id] = playerBossDetails;
              localStorage.setItem('storedPlayerBossData', JSON.stringify(playerBossData));
            }
          }
        }

        if(allBossComments?.length && allBossComments[0].length < 10) {
          setAllBossComments([
            [
              newComment,
              ...allBossComments[0]
            ],
            ...allBossComments.slice(1)
          ]);
        } else if(!allBossComments.length || allBossComments[0]?.length === 10) {
          const newPage = [newComment];
          setAllBossComments([
            newPage,
            ...allBossComments
          ]);
        }
        setCommentPage(0);
      }
    });

    return () => {
      bossPlayerData.close();
      bossCommentsUpdateRef.close();
      playerCommentsUpdatRef.close();
    }
  }, [allBossComments, boss, currentHealth, deadBoss, deadBosses, expanded, incomingPlayerComment, playerBossDetails, playerBossDetailsBackup, playerHealthComments, storedPlayerName]);

  const refreshBossComments = (boss) => {
    if(expanded) {
      setLoadingComments(true);
      setAllBossComments([]);
      fetch(boss.commentData).then((res) => res.json()).then((comments) => {
        setLoadingComments(false);
        setAllBossComments(comments);
        setCommentPage(0);
      });
    }
  }

  const handleExpandClick = () => {
    if(boss.comments.length && !allBossComments.length) {
      setAllBossComments(boss.comments);
    }
    setExpanded(!expanded);
  };

  const handleCommandClick = (event, link, text) => {
    event.preventDefault();
    navigator.clipboard.writeText(text);
    window.open(link, '_blank', 'noopener,noreferrer');
  };
  
  const closeAllComments = () => {
    setAllBossComments([]);
  }

  const closeSingleComment = (closeComment) => {
    setAllBossComments([
      ...allBossComments.slice(0, currentCommentPage),
      [
        ...allBossComments[currentCommentPage].filter(comment => comment.id !== closeComment.id)
      ],
      ...allBossComments.slice(currentCommentPage + 1)
    ].filter(page => page.length));
  } 

  const showReply = (seletedComment, selectedReply, indexes) => {
    const [commentIndex, replyIndex] = indexes;
    
    setAllBossComments([
      ...allBossComments.slice(0, currentCommentPage),
      [
        ...allBossComments[currentCommentPage].slice(0, commentIndex),
        {
          ...seletedComment,
          replies: [
            ...seletedComment.replies.slice(0, replyIndex),
            {
              ...selectedReply,
              show: !selectedReply.show,
            },
            ...seletedComment.replies.slice(replyIndex + 1)
          ]
        },
        ...allBossComments[currentCommentPage].slice(commentIndex + 1),
      ],
      ...allBossComments.slice(currentCommentPage + 1)
    ]);
  }

  const handleCommentsPageChange = (e, page) => {
    setCommentPage(page - 1);
  }

  const getFlairColor = (flairText) => {
    // eslint-disable-next-line no-unused-vars
    const [race, levels] = flairText.trim().split(' ');
    switch(race) {
      case '':
      case 'Gnome':
      case 'Troll':
      case 'Demon':
        return '#FFF'
      default:
        return '#000'
    }
  }

  const classIcon = (className) => {
    switch(className) {
      case 'magic':
        return '🔮';
      case 'melee':
        return '⚔️';
      case 'range':
        return '🏹'
      default:
        return '❓'
    }
  }
  
  try {

    return (
      <Card sx={{ maxWidth: '100%', border: 5, borderColor: boss.flair.color }}>
        <CardHeader
          title={
            <>
              <Typography variant="span" color="text.secondary" className="boss-stars" style={{backgroundColor: boss.flair.color}}>
                {boss.bossClass}
                {boss.stars.join('')}
              </Typography>
              <Typography variant="div" color="text.secondary" className="boss-health" style={{backgroundColor: boss.flair.color}}>
              <FavoriteIcon /><span> {currentHealth}/{boss.health.maxHealth}</span>
              </Typography>
              <Link href={boss.permalink} underline="none" target="_blank" rel="noreferrer" title={boss.title}>
                <Typography gutterBottom  color="primary" variant="h5" component="div" 
                  sx={{
                    overflow: "hidden",
                    textOverflow: "ellipsis",
                    whiteSpace: "pre-line",
                    display: "-webkit-box",
                    WebkitLineClamp: "1",
                    WebkitBoxOrient: "vertical",
                  }}
                >
                  {boss.title}
                </Typography>
              </Link>
            </>
          }
        />
        <BorderLinearProgress variant="determinate" value={(currentHealth / boss.health.maxHealth) * 100} sx={{'& .MuiLinearProgress-bar1Determinate': {backgroundColor: boss.flair.color}, borderRadius: '0px'}} ></BorderLinearProgress>
        <Flippy
          flipOnClick={false}
          isFlipped={expanded}
          flipDirection="horizontal"
        >
          <FrontSide style={{padding: 0}} sx={{position: 'relative'}}>
            <CardMedia
              component="img"
              height="200"
              width="100%"
              image={boss.image}
              alt={boss.title}
            />
            <CardContent sx={{position: 'relative'}}>
              {storedPlayerName ? <>
                <Snackbar
                  anchorOrigin={{ vertical: "top", horizontal: "center" }}
                  open={showResult || playerNotice.show}
                  sx={{width: '100%'}}
                  autoHideDuration={10000}
                  >
                    <Alert
                        onClose={() => showResult ? setShowResult(false) : setPlayerNotice(defaultPlayerNotice)}
                        severity={showResult ? 'success' : playerNotice.severity}
                        variant={showResult ? 'outlined' : 'filled'}
                        sx={{ backgroundColor: showResult ? '#FFF' : '' }}
                    >
                        {showResult ? <>
                          <AlertTitle><strong>Player Breakdown</strong></AlertTitle>
                          <table className="resulttable">
                            <caption><strong>Remaining: </strong>{playerBossDetails.playerHealth}hp</caption>
                            <tbody>
                              <tr>
                                <th title="Dmg Dealt">💥</th><td>{playerBossDetails.damage}</td>
                                <th title="Dmg Taken">💘</th><td>{playerBossDetails.damageTaken ? `-${playerBossDetails.damageTaken}` : '🛡️'}</td>
                              </tr>
                              <tr>
                                <th title="Gold">💰</th><td>+{playerBossDetails.gold}</td>
                                <th title="Con XP">❤️</th><td>+{playerBossDetails.conXP}</td>
                              </tr>
                              <tr>
                                <th title="Class XP">{classIcon(playerBossDetails.className)}</th><td>+{playerBossDetails.classXP}</td>
                                <th title="RP">🏅</th><td>{playerBossDetails.rp ? `+${playerBossDetails.rp}` : playerBossDetails.rp}</td>
                              </tr>
                            </tbody>
                          </table>
                        </> : playerNotice.show ? <>
                        <AlertTitle><strong>Error!</strong></AlertTitle>
                        <strong>{playerNotice.message}</strong>
                        </> : ''}
                    </Alert>
                </Snackbar>
                {!playerBossDetails.loading ? <h4 style={{marginTop: 0}}>Your last attack:<br />{playerBossDetails.created ? <Moment fromNow>{playerBossDetails.created}</Moment> : '¯\\_(ツ)_/¯'}</h4> : ''}
                  {!playerBossDetails.loading ? 
                  <>
                    <Badge badgeContent={<IconButton disabled={playerBossDetails.waitingForBot} onClick={() => setShowResult(!showResult)} color="secondary" aria-label="info" edge="start"><InfoTwoToneIcon sx={{bgcolor: '#FFF'}} /></IconButton>}>
                      <TextField 
                      variant="outlined" 
                      color='secondary'
                      value={playerBossDetails.command || 'No recent attacks!'} 
                      label="Last command"
                      className="float"
                      disabled
                      InputLabelProps={{
                        style: { color: '#5F3E5F' },
                      }}
                      sx={{
                        marginTop: 0,
                        "& .MuiInputBase-root.Mui-disabled": {
                            "& > fieldset": {
                                borderColor: "#5F3E5F"
                            }
                        }
                      }}
                      InputProps={{
                        endAdornment: (
                          playerBossDetails.elementResult && !playerBossDetails.waitingForBot ? 
                          <>
                            <Divider orientation="vertical" flexItem color="secondary" />
                            <img src={elementIcons[playerBossDetails.element]} alt={playerBossDetails.element} style={{backgroundSize: 24, height: 24, width: 24, marginLeft: 10}}/>
                            <span className="commentResult">{playerBossDetails.elementResult}</span>
                          </> : playerBossDetails.waitingForBot ? <><Divider orientation="vertical" flexItem color="secondary" /><Box sx={{marginLeft: '10px'}}><CircularProgress size="2rem" /></Box></> : ''
                        )
                      }}
                      />
                    </Badge>
                    <br/>
                    <ButtonGroup variant="outlined" aria-label="Copy actions" sx={{marginTop: '10px'}} className="float">
                      {playerBossDetails.command ?
                        <CopyToClipboardButton 
                        text={playerBossDetails.command} 
                        title={playerBossDetails.command ? `Copy last command "${playerBossDetails.command}"`: 'Open boss to ATTACK!'} 
                        variant="outlined"/>
                        : ''
                      }
                      <Button 
                        variant="outlined" 
                        color="secondary" 
                        title={playerBossDetails.command ? `Copy last command "${playerBossDetails.command}" and open boss.`: 'Open boss to ATTACK!'}
                        onClick={(e) => handleCommandClick(e, boss.permalink, playerBossDetails.command)}>
                        {playerBossDetails.command ?
                          <><ContentCopyRoundedIcon /><ArrowForwardIcon /></>
                          : ''}
                        <RedditIcon sx={{color: '#ff4500'}} />
                      </Button>
                    </ButtonGroup> 
                  </> : <CircularProgress size="5rem" />}
              </> : 'Enter a username'}
            </CardContent>
          </FrontSide>
          <BackSide style={{overflow: expanded ? 'auto': 'hidden', padding: 0 }}>
            <CardContent sx={{position: 'relative'}}>
              <ButtonGroup fullWidth={true} size={'small'}>
                <IconButton
                  onClick={() => refreshBossComments(boss)}
                  size={"small"}
                >
                  {loadingComments ? <CircularProgress size="1rem" /> : <RefreshIcon /> }
                </IconButton>
                <Button variant="contained" endIcon={<CloseIcon />} onClick={() => closeAllComments()} sx={{borderRadius: '4px !important'}} disabled={!allBossComments.length} size={"small"}>
                  Dismiss All
                </Button>
              </ButtonGroup>
              <Box style={{position: 'sticky', top: -1, zIndex:1000, backgroundColor: '#fff'}}>
                {allBossComments.length > 1 ? <Stack alignItems="center"><Pagination sx={{margin: '5px 0'}} count={allBossComments.length} variant="outlined" shape="rounded" size={isMobile ? 'large' : 'small'} siblingCount={isMobile ? 0 : 1} boundaryCount={isMobile ? 1 : 0} onChange={handleCommentsPageChange} /></Stack> : ''}
              </Box>
              {allBossComments.length && allBossComments[currentCommentPage].length ? <>
                    <Stack spacing={1} divider={<Divider flexItem />}>
                      {allBossComments[currentCommentPage].map((alert, aIndex) => (
                        <Alert variant="outlined" sx={{bgcolor: alert.bgColor, zIndex: 100, color: getFlairColor(alert.flairText), textAlign: 'left', width: '100%', border: '2px solid #000'}} key={alert.id} icon={false} onClose={(e) => {closeSingleComment(alert, aIndex)}}>
                          {/* , '& .MuiAlert-action': {marginLeft: 0, paddingLeft: 0,maxHeight: '40px', position: 'relative', right: '-50px',top: '-10px'} */}
                          <AlertTitle>
                            <b>{alert.author}</b>
                              <br />
                              <small style={{fontSize: '0.75rem', whiteSpace: 'nowrap'}}><img className="authorFlairEmoji" alt={alert.rankText} src={alert.rankIconLink} style={{maxHeight: 15, margin: 0}} /> {alert.flairText}</small>
                              <br />
                              <small><i><Moment fromNow>{alert.created}</Moment></i></small>
                          </AlertTitle>
                          {alert.body}
                          {alert.replies.length && alert.replies.at(0).body ? alert.replies.map((reply, rIndex) => (
                            <div key={reply.id}>
                              <Divider flexItem />
                              <Collapse in={reply.show} timeout="auto" unmountOnExit>
                                <Button 
                                  color='secondary'
                                  variant="contained" 
                                  endIcon={reply.show ? <ExpandLessIcon /> : <ExpandMoreIcon />}
                                  onClick={(e) => {showReply(alert, reply, [aIndex, rIndex])}}>
                                  <small>{reply.show ? 'Hide': 'Show'} reply</small>
                                </Button><br/>
                                <Markdown>{reply.body}</Markdown>
                              </Collapse>
                              <Button 
                                color='secondary'
                                variant="contained" 
                                endIcon={reply.show ? <ExpandLessIcon /> : <ExpandMoreIcon />}
                                onClick={(e) => {showReply(alert, reply, [aIndex, rIndex])}}>
                                <small>{reply.show ? 'Hide': 'Show'} reply</small>
                              </Button>
                          </div>
                          )): ''}
                      </Alert>
                      ))}
                    </Stack>
                  </>: !loadingComments ? <Typography color={'#ccc'} variant="h5">Load comments</Typography> : <><Skeleton style={{margin: '5px 0'}} animation="wave" variant="rounded" width="100%" height={isMobile ? 40 : 30}></Skeleton><Stack spacing={1} divider={<Divider flexItem />}>
                    {[0,1,2,3,4,5,6,7,8,9].map(skelly => <Skeleton key={skelly} animation="wave" variant="rounded" width="100%" height={160}></Skeleton>)}
                  </Stack></>}
            </CardContent>
          </BackSide>
        </Flippy>
        <CardActions 
          
          sx={{
            display: 'flex',
            justifyContent: 'space-around',
            m: 0,
            p: 0
          }}>
          <Box>
            <table className="maxTable">
              <thead>
                <tr>
                  <th>💰</th><th>💥</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>{maxGold}</td><td>{maxDmg}</td>
                </tr>
              </tbody>
            </table>
          </Box>
          <Box sx={{margin: 'auto', marginLeft: 'auto !important'}}>
            <Stack
                direction="row"
                divider={<Divider orientation="vertical" flexItem />}
                spacing={1}
                justifyContent="center"
                alignItems="center"
                fontSize='2rem'
              >
              {boss.specials.map(special => (
              <Badge 
                badgeContent={boss.specialStats[special.letter].length} 
                color="secondary" 
                anchorOrigin={{
                vertical: 'top',
                horizontal: 'right',
                }}
                overlap="circular"
                key={special.letter}
              >
                <span title={`${special.title}:\n${special.desc}`}>{special.icon}</span>
              </Badge>
              ))}
            </Stack>
          </Box>
          <Box>
            <ExpandMore
              expand={expanded}
              onClick={() => handleExpandClick()}
              aria-expanded={expanded}
              aria-label="Flip over for Live Comments"
              title="Flip over for Live Comments"
              size="large"
              edge="start"
              color="secondary"
            >
              <FlipIcon />
            </ExpandMore>
          </Box>
          
        </CardActions>

      </Card>
    );

  } catch(e) {
    console.log(e);
    return  (<div style={{color: '#FFF'}}>Error Loading Boss!</div>)
  }
};

export { BossDetails };