
import useSWR from 'swr';
import { Backdrop } from '@mui/material';
import CircularProgress from '@mui/material/CircularProgress';
import RedditIcon from '@mui/icons-material/Reddit';
import { BossList } from "../components"

// created function to handle API request
const fetcher = (...args) => fetch(...args).then((res) => res.json());

function Home() {
  const {
    data: bosses,
    error,
    isValidating,
  } = useSWR('https://api.spaghet.io/kotd/v1/boss-list', fetcher, { revalidateOnFocus: false });

  //Handles error and loading state
  if (error) return <div className='failed'>Rate Limited by <RedditIcon /></div>;
  if (isValidating) return <Backdrop
                              sx={(theme) => ({ color: '#fff', zIndex: theme.zIndex.drawer + 1 })}
                              open={true}
                            >
                              <CircularProgress color="inherit" />
                            </Backdrop>;

  return (
    <div style={{margin: 'auto'}}>
        <BossList bossData={bosses} />
    </div>
  );
}

export default Home;