/* eslint-disable no-useless-computed-key */
import { useState, useRef, useEffect, useCallback } from 'react'
import {Paper, Skeleton} from '@mui/material';
import InputBase from '@mui/material/InputBase';
import IconButton from '@mui/material/IconButton';
import SaveAltRoundedIcon from '@mui/icons-material/SaveAltRounded';
import { setInterval, clearInterval  } from "worker-timers";

import ClearIcon from '@mui/icons-material/Clear';
import "./component-css/player-details.css";

const PlayerDetails = () => {
  
  const playerInput = useRef('');
  const [updatedHealth, updateHealth] = useState('');
  const [storedPlayerName, setStoredPlayerName] = useState(localStorage.getItem('storedPlayerName') || '');
  const [storedPlayerData, setStoredPlayerData] = useState(JSON.parse(localStorage.getItem('storedPlayerData')) || {
    isValid: false,
    loading: false
  });
  
  const playerBossBroadcast = useRef();

  const clearPlayer = (e) => {
    setStoredPlayerName('');
    localStorage.setItem('storedPlayerName', '');
    localStorage.setItem('storedPlayerBossData', '{}');
    localStorage.setItem('storedPlayerData', '{}');
    setStoredPlayerData({
      isValid: false,
      loading: false
    })
    window.location.reload();
  }

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const handleSubmit = useCallback(() => {
    setStoredPlayerData({
      ...storedPlayerData,
      loading: true
    });
    localStorage.setItem('storedPlayerData', JSON.stringify(storedPlayerData));

    fetch(`https://api.spaghet.io/kotd/v1/player-details/${playerInput.current || storedPlayerName}`).then((res) => res.json()).then(playerData => {
      if(playerData?.isValid) {
        const tempPlayerData = {
          ...playerData.forPlayer,
          isValid: true,
          loading: false
        };
        setStoredPlayerData(tempPlayerData);
        setStoredPlayerName(playerData.forPlayer.player);
        localStorage.setItem('storedPlayerName', playerData.forPlayer.player);
        localStorage.setItem('storedPlayerData', JSON.stringify(tempPlayerData));
        localStorage.setItem('storedPlayerBossData', JSON.stringify(playerData.forBosses));
        playerBossBroadcast.current.postMessage(playerData.forBosses);
      } else {
        localStorage.setItem('storedPlayerName', '');
        localStorage.setItem('storedPlayerBossData', '{}');
        localStorage.setItem('storedPlayerData', '{}');
        setStoredPlayerData({
          ...storedPlayerData,
          isValid: false,
          loading: false
        });
      }
    })
  });
  

  if (!playerBossBroadcast.current) {
    playerBossBroadcast.current = new BroadcastChannel('boss-player-data');
    handleSubmit()
  }

  const handleTextChange= e => playerInput.current = e.target.value;

  const handleBlur = e => playerInput.current = e.target.value;

  const handleEnter = e => {
    if (e.key === 'Enter') {
      if(e.target.value) {
        handleSubmit();
      }
    }
  };

  useEffect(() => {
    const playerHealthUpdate =  new BroadcastChannel('player-health');

    const refresh = setInterval(handleSubmit, 60000);

    playerHealthUpdate.addEventListener("message", (event) => {
      const playerHealth = event?.data;
      if(playerHealth) {
        updateHealth(playerHealth);
      }
    })

    return () => {
      clearInterval(refresh);
      playerHealthUpdate.close();
    }
  }, [handleSubmit]);

  if (storedPlayerData.loading) {
    return <>
      <Skeleton variant="circular" width={45} height={45} sx={{display: 'inline-block', verticalAlign: 'middle', bgcolor: '#CCC'}} />
      <Skeleton variant="rounded" width={146} height={65} sx={{display: 'inline-block', verticalAlign: 'middle', bgcolor: '#CCC'}} />
    </>;
  }

  if(!storedPlayerData.isValid) {
    return <>
      <Paper
        sx={{ p: '2px 4px', display: 'flex', alignItems: 'center', maxWidth: 200 }}>
        <InputBase
          sx={{ flex: 1 }}
          placeholder="Reddit Username"
          onChange={handleTextChange}
          onBlur={handleBlur}
          onKeyDown={handleEnter}
          ref={playerInput}
        />
        <IconButton aria-label="Set Player" className={'ClearButton'} onClick={handleSubmit} >
          <SaveAltRoundedIcon />
        </IconButton>
      </Paper>
    </>
  }

  try {
    

    const wounded = (updatedHealth || storedPlayerData.health) / storedPlayerData.maxHealth  <= 0.25;

    return (
      <div style={{color: '#000'}}>
        <img className="authorFlairEmoji" alt={storedPlayerData.flair.rank} src={storedPlayerData.flair.icon} style={{maxHeight: 45}} /> 
        <div className="flair authorFlair" style={{backgroundColor:storedPlayerData.flair.color}}>
          {storedPlayerName}<IconButton  sx={{ position: 'absolute', top: -5, right: -10}} variant="outlined" onClick={clearPlayer}><ClearIcon /></IconButton><br/>
          {storedPlayerData.flair.text}<br/>
          {wounded ? '💓' : '❤️'}{`${updatedHealth || storedPlayerData.health}/${storedPlayerData.maxHealth}hp`}
        </div> 
        
      </div>
    );

  } catch(e) {
    console.log(e);
    return <div className='failed'>Error loading profile<br/>Refresh</div>
  }
};


export { PlayerDetails };