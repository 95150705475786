import {useState, useEffect, useRef } from 'react';
import { BossDetails, TotalMaxDmg } from "./"
import { Container } from '@mui/material';
import { setInterval  } from "worker-timers";

import Grid from '@mui/material/Unstable_Grid2';


const initialPostsWS = new WebSocket('wss://api.spaghet.io/ws/kotd/boss-posts');

const BossList = ({ bossData }) => {

  const [columnWidth] = useState(4);
  const [deadBosses, deadBoss] = useState([]);
  const [bossPostsWS, openPostsWS] = useState(initialPostsWS);
  const [bosses, updateBosses] = useState(bossData);
  const [storedPlayerName] = useState(localStorage.getItem('storedPlayerName'));

  const isMounted = useRef();

  useEffect(() => {

    isMounted.current = true;

    bossPostsWS.onopen = function(event) {
      //console.log('opening PING SetInterval')
      setInterval(() => {
        if([WebSocket.CLOSED,WebSocket.CLOSING].includes(bossPostsWS.readyState)) {
          openPostsWS(new WebSocket('wss://api.spaghet.io/ws/kotd/boss-posts'));
        }
        bossPostsWS.send(`Ping`);
      }, 55000);
    };
    
    bossPostsWS.onmessage = function(event) {
      const newBoss = JSON.parse(event?.data);
      updateBosses([
        newBoss,
        ...bosses
      ]);
    };

    bossPostsWS.onclose = function(event) {
      if(isMounted.current) {
        openPostsWS(new WebSocket('wss://api.spaghet.io/ws/kotd/boss-posts'));
      }
    };

    return () => {
    };

  }, [bossPostsWS, bosses, storedPlayerName]);

  return (
      <Container>
        <TotalMaxDmg bossData={bosses} />
        <Grid container spacing={1} alignItems="center" justifyContent="center" sx={{maxWidth: '100%'}}>
          {bosses &&
            bosses.sort((a, b) => a.health.currentHealth - b.health.currentHealth).filter(boss => !deadBosses.includes(boss.id)).map((boss, index) => (
              <Grid xs={11} sm={6} md={columnWidth} lg={columnWidth} xl={3} key={boss.id} alignItems="center" justifyContent="center" style={{position: 'relative', marginTop: 10}}>
                <BossDetails boss={boss} deadBoss={deadBoss} deadBosses={deadBosses}  />
              </Grid>
            ))}
        </Grid>
      </Container>
  );
};

export { BossList };