import { useState, useEffect } from 'react';

const TotalMaxDmg = ({ bossData }) => {

    const initialMaxDmg = bossData.reduce((acc, boss) => acc + boss.maxDmg, 0);

    const [totalMaxDmg, setMaxDmg] = useState(initialMaxDmg);
    const [bosses, setBosses] = useState(bossData);

    useEffect(() => {
      const bossDmgUpdate = new BroadcastChannel('boss-damage');
  
      bossDmgUpdate.addEventListener("message", (event) => {
        const bossUpdate = event?.data?.boss;
        const maxDmg = event?.data?.maxDmg;

        setBosses(bosses.map(boss => {
          if(boss.id === bossUpdate.id) {
            return {
              ...boss,
              maxDmg
            }
          }
          return boss;
        }));

        const newTotalMaxDmg = bosses.reduce((acc, boss) => acc + boss.maxDmg, 0);

        setMaxDmg(newTotalMaxDmg);
      })
  
      return () => {
        bossDmgUpdate.close();
      }
    }, [bosses]);
    
  return (
    <h4 style={{color: '#FFFFFF'}}>Total Max Damage: 💥{totalMaxDmg}</h4>
  );
};

export { TotalMaxDmg };