import { useState, useEffect } from "react";
import { Snackbar, Alert, AlertTitle } from "@mui/material";

const initialAnnouncementWS = new WebSocket('wss://api.spaghet.io/ws/kotd/announcements');

const Announcements = ({text, title, variant}) => {
  const [open, setOpen] = useState(false);
  const [announcement, setAnnouncement] = useState({message: '', severity: '', for: ''});
  const [announcementWS, openCommentWS] = useState(initialAnnouncementWS);
  const [storedPlayerName] = useState(localStorage.getItem('storedPlayerName'));

  useEffect(() => {
    let ping;
    announcementWS.onopen = function(event) {
      ping = setInterval(() => {
        announcementWS.send(`Announcement Ping from: ${storedPlayerName}`);
      }, 55000)
    };
    
    announcementWS.onmessage = function(event) {
      const announcement = JSON.parse(event.data);
      if(announcement.message === 'forceReload') {
        window.location.reload(true);
        return;
      }
      if(announcement.for === 'All' || announcement.for.toLowerCase() === storedPlayerName.toLowerCase()) {
        setAnnouncement(announcement);
        setOpen(true);
      }
      if(announcement.for.toLowerCase() === storedPlayerName.toLowerCase()) {
        announcementWS.send(`Announcement Received for: ${storedPlayerName}`);
      }
    };

    announcementWS.onclose = function(event) {
      openCommentWS(new WebSocket('wss://api.spaghet.io/ws/kotd/announcements'));
    };

    return () => {
      clearInterval(ping);
    };

  }, [announcementWS, storedPlayerName]);

  return (
    <>
        <Snackbar
        anchorOrigin={{ vertical: "top", horizontal: "center" }}
        open={open}
        >
            <Alert
                onClose={() => setOpen(false)}
                severity={announcement.severity}
                variant="outline"
                sx={{ width: '100%', bgcolor: "background.paper" }}
            >
                <AlertTitle><strong>For: {announcement.for}</strong></AlertTitle>
                {announcement.message}
            </Alert>
        </Snackbar>
    </>
  );
};

export { Announcements }