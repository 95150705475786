import * as React from 'react';
import useSWR from 'swr';
import Box from '@mui/material/Box';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import {Container} from '@mui/material';
import Select from '@mui/material/Select';
import PropTypes from 'prop-types';
import SwipeableViews from 'react-swipeable-views';
import { useTheme } from '@mui/material/styles';
import AppBar from '@mui/material/AppBar';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import FormGroup from '@mui/material/FormGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import Switch from '@mui/material/Switch';
import TextField from '@mui/material/TextField';


// created function to handle API request
const fetcher = (...args) => fetch(...args).then((res) => res.json());

const baseDamgeLevels = [
  {min: 1,  max: 1,  dmg: 3.5 },
  {min: 2,  max: 2,  dmg: 4   },
  {min: 3,  max: 3,  dmg: 4.5 },
  {min: 4,  max: 4,  dmg: 5   },
  {min: 5,  max: 6,  dmg: 6   },
  {min: 7,  max: 8,  dmg: 6.5 },
  {min: 9,  max: 11, dmg: 7   },
  {min: 12, max: 14, dmg: 7.5 },
  {min: 15, max: 15, dmg: 8   },
  {min: 16, max: 20, dmg: 8.5 },
  {min: 21, max: 24, dmg: 9   },
  {min: 25, max: 27, dmg: 9.5 },
  {min: 28, max: 34, dmg: 10  },
  {min: 35, max: 44, dmg: 11  },
  {min: 45, max: 45, dmg: 11.5},
  {min: 46, max: 54, dmg: 12  },
  {min: 55, max: 58, dmg: 12.5},
  {min: 59, max: 64, dmg: 13  },
  {min: 65, max: 74, dmg: 13.5},
  {min: 75, max: 75, dmg: 14  },
  {min: 76, max: 84, dmg: 14.5},
  {min: 85, max: 86, dmg: 15  },
  {min: 87, max: 94, dmg: 15.5},
  {min: 95, max: 104,dmg: 16  },
  {min: 105,max: 114,dmg: 16.5},
  {min: 115,max: 124,dmg: 17  },
  {min: 125,max: 128,dmg: 17.5},
];

const baseDamageLookup = function(lvl) {
  return baseDamgeLevels.filter(base => base.min <= lvl && base.max >= lvl)[0].dmg;
}

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`full-width-tabpanel-${index}`}
      aria-labelledby={`full-width-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Container>
          <Box>
              {children}
          </Box>
        </Container>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

const a11yProps = function(index) {
  return {
    id: `full-width-tab-${index}`,
    'aria-controls': `full-width-tabpanel-${index}`,
  };
}

const RaidMath = () => {
  const theme = useTheme();
  const [value, setValue] = React.useState(0);
  const [bloodLust, setBloodLust] = React.useState(false);
  const [weaponDamages, setWpnDamge] = React.useState({
    melee: '',
    range: '',
    magic: '',
  });
  const [elementStrengths, setEleStrength] = React.useState({
    melee: 1.5,
    range: 1.5,
    magic: 1.5,
  });

  const calculateReact = function(tab) {
    let rawReact = Math.floor(((tab.baseDmg + (weaponDamages[tab.name]*elementStrengths[tab.name]))*(bloodLust ? 2 : 1))/5);
    if(rawReact <= 10) {
      return (<div style={{textAlign: 'center'}}><img src={'/assets/' + rawReact + '.svg'} alt={rawReact} style={{maxWidth: '50%'}}/></div>)
    } else if(rawReact > 10) {
      return (<div style={{textAlign: 'center'}}><img src={'/assets/10.svg'} alt="10" style={{maxWidth: '48%', display: 'inline-block'}}/> <img src={'/assets/' + rawReact.toString().split('')[1] + '.svg'} alt={rawReact} style={{maxWidth: '48%', display: 'inline-block'}}/></div>)
    }
  }


  const handleBLChange = (event) => {
    setBloodLust(event.target.checked);
  };

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const handleChangeIndex = (index) => {
    setValue(index);
  };

  const handleWpnDmgChange = (event) => {
    setWpnDamge({
      ...weaponDamages,
      [event.target.name]: event.target.value,
    });
  };

  const handleEleStrengthChange = (event) => {
    setEleStrength({
      ...elementStrengths,
      [event.target.name]: event.target.value,
    });
  };

  let storedPlayerName = localStorage.getItem('storedPlayerName');

  window.addEventListener('kotdPlayerUpdate', () => {
    storedPlayerName = localStorage.getItem('storedPlayerName');
  });

  const {
    data: player,
    error,
    isValidating,
  } = useSWR(`https://api.spaghet.io/kotd/v1/player-details/${storedPlayerName ? storedPlayerName : 'KickOpenTheDoorBot'}`, fetcher, { revalidateOnFocus: false});

  // Handles error and loading state
  if (error) return <div className='failed'>failed to load</div>;
  if (isValidating) return <div className="Loading">Loading...</div>;

  if(!storedPlayerName || player.notFound) return <h3>Enter a username</h3>
  
  const {
    forPlayer: {
      levels: {
        melee,
        ranged,
        magic
      }
    }
  } = player;

  let playerStats = [
    {
      title: 'Melee ⚔️', 
      name: 'melee',
      lvl: melee,
      baseDmg: baseDamageLookup(melee),
      wpnDmg: 0},
    {
      title: 'Range 🏹', 
      name: 'range', 
      lvl: ranged, 
      baseDmg: baseDamageLookup(ranged),
      wpnDmg: 0},
    {
      title: 'Magic 🔮', 
      name: 'magic',
      lvl: magic, 
      baseDmg: baseDamageLookup(magic),
      wpnDmg: 0}
  ];

  return (
    <>
      <h3 style={{color: '#FFFFFF'}}>Raid Math</h3>
      <Box sx={{ bgcolor: 'background.paper', width: 500, maxWidth: '98vw' }}>
        <AppBar position="static">
          <Tabs
            value={value}
            onChange={handleChange}
            indicatorColor="secondary"
            textColor="inherit"
            variant="fullWidth"
          >
            {playerStats.map((tab, index) => <Tab label={tab.title + ' (' + tab.lvl + ')'} {...a11yProps(index)} key={tab.title}/>)}
          </Tabs>
        </AppBar>
        <SwipeableViews
          axis={theme.direction === 'rtl' ? 'x-reverse' : 'x'}
          index={value}
          onChangeIndex={handleChangeIndex}
        >
          {playerStats.map((tab, index) => (
            <TabPanel component='div' value={value} index={index} dir={theme.direction} key={tab.title + index}>
              <FormControl component="fieldset" variant="standard">
                <FormGroup>
                  <FormControlLabel
                    control={
                      <Switch checked={bloodLust} onChange={handleBLChange} name="bloodLust" />
                    }
                    label="Bloodlust"
                  />
                </FormGroup>
                <br/>
                <TextField
                  disabled
                  label="Base Damage"
                  defaultValue={tab.baseDmg}
                />
                <br/>
                <TextField
                  label="Weapon Damage"
                  name={tab.name}
                  value={weaponDamages[tab.name]}
                  onChange={handleWpnDmgChange}
                />
                <br/>
                <FormControl fullWidth>
                  <InputLabel id={tab.name + '-element-strength-label'}>Element Strength</InputLabel>
                  <Select
                    name={tab.name}
                    labelId={tab.name + '-element-strength-label'}
                    id={tab.name + '-element-strength'}
                    value={elementStrengths[tab.name]}
                    label="Element Strength"
                    onChange={handleEleStrengthChange}
                  >
                    <MenuItem value={1.5}>Weakness</MenuItem>
                    <MenuItem value={1}>Neutral</MenuItem>
                    <MenuItem value={.5}>Resist</MenuItem>
                  </Select>
                </FormControl>
                <br/>
                {calculateReact(tab)}
              </FormControl>
            </TabPanel>
          ))}
        </SwipeableViews>
      </Box>
    </>
  );
};

export { RaidMath };