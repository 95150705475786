import { Outlet, Link } from "react-router-dom";
import "../App.css";
import { useState, useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import CssBaseline from '@mui/material/CssBaseline';
import Divider from '@mui/material/Divider';
import SwipeableDrawer from '@mui/material/SwipeableDrawer';
import IconButton from '@mui/material/IconButton';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import MenuIcon from '@mui/icons-material/Menu';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import { Link as ReactRouterLink } from 'react-router-dom';
import useScrollTrigger from '@mui/material/useScrollTrigger';
import Slide from '@mui/material/Slide';
import ViewModuleIcon from '@mui/icons-material/ViewModule';
import BackpackIcon from '@mui/icons-material/Backpack';
import ShoppingCartIcon from '@mui/icons-material/ShoppingCart';
import CalculateIcon from '@mui/icons-material/Calculate';
import { PlayerDetails, Announcements } from "../components";
import { BrowserView, isMobile } from 'react-device-detect';

function HideOnScroll(props) {
  const { children, window } = props;
  // Note that you normally won't need to set the window ref as useScrollTrigger
  // will default to window.
  // This is only being set here because the demo is in an iframe.
  const trigger = useScrollTrigger({
    target: window ? window() : undefined,
  });

  return (
    <Slide appear={false} direction="down" in={!trigger}>
      {children}
    </Slide>
  );
}

HideOnScroll.propTypes = {
  children: PropTypes.element.isRequired,
  /**
   * Injected by the documentation to work in an iframe.
   * You won't need it on your project.
   */
  window: PropTypes.func,
};

const initialCommentWS = new WebSocket('wss://api.spaghet.io/ws/kotd/boss-comments');

const Layout = (props) => {
  // eslint-disable-next-line no-unused-vars

  const drawerWidth = 240;
  const pages = [
    {title: 'Boss List', to: '/boss-list', icon: <ViewModuleIcon style={{verticalAlign:'middle'}} />}, 
    {title: 'Inventory', to: '/inventory', icon: <BackpackIcon style={{verticalAlign:'middle'}}  />}, 
    {title: 'Shop', to: '/shop', icon: <ShoppingCartIcon style={{verticalAlign:'middle'}}  />}, 
    {title: 'Raid Math', to: '/raid', icon: <CalculateIcon style={{verticalAlign:'middle'}}  />}
  ];
    
  const { window } = props;
  const [mobileOpen, setMobileOpen] = useState(false);
  const [storedPlayerName] = useState(localStorage.getItem('storedPlayerName') || false);
  const [bossCommentsWS, openCommentWS] = useState(initialCommentWS);

  const isMounted = useRef();

  useEffect(() => {

    isMounted.current = true;

    const bossCommentsUpdateRef = new BroadcastChannel('boss-comments');
    const playerCommentsUpdatRef = new BroadcastChannel('player-comments');

    bossCommentsWS.onopen = function(event) {
      //console.log('opening PING SetInterval')
      setInterval(() => {
        if([WebSocket.CLOSED,WebSocket.CLOSING].includes(bossCommentsWS.readyState)) {
          openCommentWS(new WebSocket('wss://api.spaghet.io/ws/kotd/boss-comments'));
        }
        bossCommentsWS.send(`Ping`);
      }, 55000);
    };
    
    bossCommentsWS.onmessage = function(event) {
      const comment = JSON.parse(event.data).payload;
      switch(comment.author) {
        case 'KickOpenTheDoorBot':
          bossCommentsUpdateRef.postMessage(comment);
          break;
        default:
          playerCommentsUpdatRef.postMessage(comment);
          break;
      }
    };

    bossCommentsWS.onclose = function(event) {
      if(isMounted.current) {
        openCommentWS(new WebSocket('wss://api.spaghet.io/ws/kotd/boss-comments'));
      }
    };

    return () => {
      isMounted.current = false;
      bossCommentsUpdateRef.close();
      playerCommentsUpdatRef.close();
    };

  }, [bossCommentsWS]);

  const handleDrawerToggle = () => {
    setMobileOpen((prevState) => !prevState);
  };

  const drawer = (
    <Box onClick={handleDrawerToggle} sx={{color: '#FFF'}}>
      <Typography variant="h6" sx={{ my: 2, textAlign: 'center', margin: '0', bgcolor: 'primary.main', height: 56, lineHeight: '56px' }}>
        Spaghet KOTD v{process.env.REACT_APP_VERSION}
      </Typography>
      <Divider />
      <List>
        {pages.map((page) => (
          <ListItem key={page.title} disablePadding>
            <ListItemButton component={ReactRouterLink} sx={{ textAlign: 'center' }} to={page.to} divider={true}>
              {page.icon} {page.title}
            </ListItemButton>
          </ListItem>
        ))}
      </List>
      {isMobile && storedPlayerName ? <Box sx={{ margin: 'auto'}}><PlayerDetails /></Box> : ''}
    </Box>
  );

  const container = window !== undefined ? () => window().document.body : undefined;

  return (
    <>
      <CssBaseline />
      <HideOnScroll {...props}>
        <AppBar component="nav">
          <Toolbar>
            <IconButton
              color="inherit"
              aria-label="open drawer"
              edge="start"
              onClick={handleDrawerToggle}
              sx={{ mr: 2, display: { sm: 'none' } }}
            >
              <MenuIcon />
            </IconButton>
            <Typography
              variant="h6"
              component="div"
              sx={{ flexGrow: 1, display: { xs: 'none', sm: 'block' } }}
            >
              Spaghet KOTD v{process.env.REACT_APP_VERSION}
            </Typography>
            <Box sx={{ display: { xs: 'none', sm: 'block' } }}>
              {pages.map((page) => (
                <Link component={ReactRouterLink} key={page.title} style={{color: '#FFF', margin: '10px', verticalAlign:'middle'}}  to={page.to}>
                  {page.icon} {page.title}
                </Link>
              ))}
            </Box>
            <BrowserView>
              <Divider orientation="vertical" flexItem sx={{xs: 'none', sm: 'block', opacity: "0.6", backgroundColor: '#FFF'}} />
            </BrowserView>
            <Box sx={{ margin: 'auto'}}>
              {isMobile && !storedPlayerName ? <PlayerDetails /> : <BrowserView><PlayerDetails /></BrowserView>}
            </Box>
          </Toolbar>
        </AppBar>
      </HideOnScroll>
      <nav>
        <SwipeableDrawer
          container={container}
          swipeAreaWidth={40}
          minFlingVelocity={500}
          variant="temporary"
          open={mobileOpen}
          onOpen={handleDrawerToggle}
          onClose={handleDrawerToggle}
          ModalProps={{
            keepMounted: true, // Better open performance on mobile.
          }}
          sx={{
            display: { xs: 'block', sm: 'none' },
            '& .MuiDrawer-paper': { boxSizing: 'border-box', width: drawerWidth, bgcolor: 'secondary.main' }
          }}
        >
          {drawer}
        </SwipeableDrawer>
      </nav>
      <div className="App-base">
        <Announcements/>
        <Outlet/>
        <footer>
          <p style={{color: '#FF'}}>This tool is provided by u/SpaghetGaming for personal use as well as the KOTD Discord</p>
        </footer>
      </div>
    </>
  )
};
Layout.propTypes = {
  /**
   * Injected by the documentation to work in an iframe.
   * You won't need it on your project.
   */
  window: PropTypes.func,
};

export default Layout;